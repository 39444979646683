<template>
	<div class='pt30'>
		<div class="top_box mb40">
			<div class="QA_box">百问百答</div>
		</div>

		<!-- 折叠面板 -->
		<van-collapse v-model="activeName" accordion>
			<van-collapse-item :title="item.questionName" :name="i" v-for="(item,i) in qaList" :key="i">
				<div class="pt14 pl14 pr14 pb14 fs12 fw-500" style="background: rgba(247, 181, 0, .14);color: #121212;"
					v-html="item.answer"></div>
			</van-collapse-item>
		</van-collapse>

		<!-- 分页 -->
		<div class="pt19">
			<van-pagination v-model="query.pageNum" :total-items="count" :show-page-size="query.pageSize" force-ellipses
				@change='change'>
				<template #prev-text>
					<van-icon name="arrow-left" />
				</template>
				<template #next-text>
					<van-icon name="arrow" />
				</template>
				<template #page="{ text }">{{ text }}</template>
			</van-pagination>
		</div>

		<!-- 联系我们 -->
		<div class="mt40 pl16 pr16 text-center">
			<div class="fs16 fw-600">联系我们</div>
			<div class="fs11 color9D mt10 mb20">如您对于我们的产品或服务，请您随时联系我。同时如果您对我们有任何宝贵意见请参考以下联系方式联系我们。期待与您的沟通。</div>
			<img src="@/assets/images/aboutUs/map.png" class="w322 h260">
		</div>
		<!-- //联系我们 -->

		<!-- 联系方式 -->
		<div class="w-max  fs16 flex row-column col-center pt30  pb40 mt20" style="background: #F9F9F9;">
			<van-icon name="phone" size="24" />
			<div class="mt12 mb30">
				<div>重庆：023-62627264</div>
				<div>北京：01067869821</div>
				<div>杭州：85238218</div>
			</div>
			<van-icon name="invitation" size="24" />
			<div class="mt12 mb30">info@e-rijiben.com</div>
			<van-icon name="location" size="24" />
			<div class="mt20 pl20 pr20">
				<div>重庆：重庆市经开区长生桥镇美林路16号A9号楼第6层</div>
				<div>杭州：杭州市拱墅区祥茂路166号华滋科欣·设计创意园2号楼1108</div>
				<div>北京：北京市北京经济技术开发区路东区科创十三街锋创科技园8号楼401</div>
			</div>
		</div>
		<!-- //联系方式 -->
	</div>
</template>

<script>
	import { getQa } from '@/api'
	export default {
		name: 'contactUs',
		data() {
			return {
				activeName: '',
				query: {
					pageNum: 1,
					pageSize: 5
				},
				qaList: [],
				count: ''
			};
		},

		components: {},

		created() {
			this.$store.commit('editNavUrl',this.$route.path)
			this.getQa()
			
		},

		mounted() {},

		methods: {
			async getQa() {
				const res = await getQa(this.query)
				this.count = res.data.count
				this.qaList = res.data.obj
			},
			change() {
				this.activeName = ''
				this.getQa()
			}
		}
	}
</script>
<style lang='scss' scoped>
	img {
		display: block;
		margin: 0 auto;
	}

	.top_box {
		position: relative;
		margin: 0 auto;
		width: 315px;
		height: 25px;
		border: 2px solid #F7B500;

		.QA_box {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, -50%);
			width: 104px;
			font-size: 16px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
			background: #fff;
			text-align: center;
		}
	}

	::v-deep .van-pagination__item--active {
		color: #fff !important;
		background: #181818;
	}

	::v-deep .van-pagination__item:active {
		background-color: #181818;
	}

	::v-deep .van-pagination__item {
		color: #181818;
	}
</style>
